/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useEffect } from 'react';
import { bool as boolean, number as numberType, string as stringType } from 'prop-types';
import {
  arrayOf,
  customType,
  typename,
  params,
  shape,
  namedFragment,
  string,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { Typography, Button, Link } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import { useImpression } from '@thd-olt-component-react/impression';
import { getMediaUrl } from './utils/getMediaUrl';
import { withImpressions } from '../hocs/withImpressions';

const MedioComposableBanner = withImpressions(
  ({ componentClass, componentId, componentPosition }) => {
    const { ref, clickID } = useImpression({
      data: {
        id: componentId,
        name: MedioComposableBanner.displayName,
        component: MedioComposableBanner.displayName,
        position: componentPosition,
        type: 'content',
      },
    });

    useEffect(() => {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('medio-composable-banner.ready');
    }, []);

    const { data, loading, error } = useDataModel('component', {
      variables: {
        componentClass,
        id: componentId,
      },
    });

    if (!data) return null;

    const {
      image,
      altText,
      smallMedia,
      altTextSmallMedia,
      title,
      subtitle,
      cta,
      destinationUrl,
      legalCopy,
    } = data.component || {};

    const imageUrl = getMediaUrl(image);
    const smallMediaUrl = getMediaUrl(smallMedia);
    const { legalText = '', legalUrl = '' } = legalCopy || {};

    const mainContent = (
      <div
        className={classNames(
          'sui-flex',
          'sui-flex-col',
          'sm:sui-flex-row',
          'sm:sui-h-[148px]',
          'md:sui-h-[116px]',
          'lg:sui-h-[118px]',
          'xl:sui-h-[134px]',
          '2xl:sui-h-[119px]',
          {
            'hover:sui-shadow-lg': !cta && destinationUrl,
          }
        )}
      >
        {/* Image */}
        <div
          className="
          sui-order-1
          sm:sui-order-2
          sm:sui-basis-[24%]
          md:sui-basis-[16%]
          xl:sui-basis-[19%]
          2xl:sui-basis-[17%]
          sui-aspect-[2/1]
          sm:sui-aspect-[4/3]"
        >
          <img
            className="
            sui-object-cover
            sui-w-full
            sui-h-full"
            src={imageUrl}
            alt={altText}
            width="100%"
            height="100%"
          />
        </div>
        {/* Content Container */}
        <div
          data-testid="medio-composable-banner-content-container"
          className={classNames(
            'sui-flex',
            'sui-items-center',
            'sm:sui-flex-nowrap',
            'sui-p-4',
            'sui-order-2',
            'sm:sui-order-1',
            'sm:sui-basis-[76%]',
            'md:sui-basis-[84%]',
            'xl:sui-basis-[81%]',
            '2xl:sui-basis-[83%]',
            'sui-paper-outlined',
            {
              'sui-flex-nowrap': !cta && smallMediaUrl,
              'sui-flex-wrap': cta,
            }
          )}
        >
          {/* Title and Subtitle Container */}
          <div
            data-testid="medio-composable-banner-title-and-subtitle-container"
            className={classNames('sui-w-full', 'sm:sui-w-auto', 'sm:sui-order-2', 'sm:sui-mb-0', {
              'sui-order-1': cta,
              'sui-mb-4': cta,
              'sui-order-2': !cta && smallMediaUrl,
            })}
          >
            <Typography
              variant="h3"
              height="tight"
              lineClamp={{
                default: '2',
                sm: '2',
                md: '1',
                lg: '1',
                xl: '1',
                '2xl': '1',
              }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                underline="none"
                variant="body-base"
                height="tight"
                lineClamp={{
                  default: '2',
                  sm: '3',
                  md: '2',
                  lg: '2',
                  xl: '2',
                  '2xl': '1',
                }}
              >
                {subtitle}
              </Typography>
            )}
          </div>
          {/* CTA Button */}
          {cta && destinationUrl && (
            <div
              style={{ minWidth: 'fit-content' }}
              className={classNames('sm:sui-order-3', 'sm:sui-ml-auto', 'sm:sui-pl-4', '[min-width: fit-content]', {
                'sui-order-2': cta,
              })}
            >
              <Button href={destinationUrl} variant="text">
                {cta}
              </Button>
            </div>
          )}
          {/* Small Media Image */}
          {smallMediaUrl && (
            <div
              className={classNames(
                'sui-min-w-12',
                'sui-min-h-12',
                'sm:sui-mx-0',
                'sm:sui-mr-4',
                'sm:sui-order-1',
                'sui-flex',
                'sui-items-center',
                {
                  'sui-order-1': !cta && smallMediaUrl,
                  'sui-mr-4': !cta && smallMediaUrl,
                  'sui-ml-auto': cta,
                  'sui-order-3': cta,
                }
              )}
            >
              <img src={smallMediaUrl} alt={altTextSmallMedia} width="48px" height="48px" />
            </div>
          )}
        </div>
      </div>
    );

    return (
      <div
        ref={ref}
        // eslint-disable-next-line react/no-unknown-property
        clickid={clickID}
        data-component-id={componentId}
        data-component-name="MedioComposableBanner"
        data-component-position={componentPosition}
        data-component="MedioComposableBanner"
        data-testid="medio-composable-banner"
        id={`${componentClass}-${componentPosition}-${componentId}`}
      >
        {/* Main Container */}
        {!cta && destinationUrl ? <Link underline="none" href={destinationUrl}>{mainContent}</Link> : mainContent}

        {/* Legal Text */}
        {legalText && (
          <div className="sui-pt-2">
            {legalUrl ? (
              <Link href={legalUrl} target="_blank" variant="body-xs">
                {legalText}
              </Link>
            ) : (
              <Typography variant="body-xs">{legalText}</Typography>
            )}
          </div>
        )}
      </div>
    );
  });

MedioComposableBanner.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  componentPosition: numberType,
};

MedioComposableBanner.defaultProps = {
  componentClass: '',
  componentPosition: 1,
};

MedioComposableBanner.displayName = 'MedioComposableBanner';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1',
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: string(),
      })
    ),
  }),
  damDownloadedContent: shape({
    url: string(),
  }),
  __typename: typename('DamMedia'),
});

MedioComposableBanner.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['MedioComposableBanner']).isRequired(),
  }).shape({
    MedioComposableBanner: namedFragment({
      inline: true,
      fragmentType: 'MedioComposableBanner',
    }).shape({
      componentName: string(),
      id: string(),
      image: DamMediaFragment,
      altText: string(),
      smallMedia: DamMediaFragment,
      altTextSmallMedia: string(),
      title: string(),
      subtitle: string(),
      cta: string(),
      destinationUrl: string(),
      legalCopy: shape({
        legalText: string(),
        legalUrl: string(),
      }),
    }),
  }),
};

export { MedioComposableBanner };
