/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useEffect } from 'react';
import { bool, number as numberType, string as stringType } from 'prop-types';
import {
  arrayOf,
  customType,
  namedFragment,
  typename,
  params,
  shape,
  string,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import { getMediaUrl } from './utils/getMediaUrl';
import { withImpressions } from '../hocs/withImpressions';

const PiccolaComposableBanner = withImpressions(
  ({ componentClass, componentId, componentPosition, lazyLoad }) => {
    const { ref, clickID } = useImpression({
      data: {
        id: componentId,
        name: PiccolaComposableBanner.displayName,
        component: PiccolaComposableBanner.displayName,
        position: componentPosition,
        type: 'content',
      },
    });

    useEffect(() => {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('piccola-composable-banner.ready');
    }, []);

    const { data, loading, error } = useDataModel('component', {
      variables: {
        componentClass,
        id: componentId,
      },
    });

    if (!data) return null;

    const {
      title,
      subtitle,
      smallMedia,
      altText,
      destinationUrl,
      legalCopy,
    } = data.component || {};

    const { legalText = '', legalUrl = '' } = legalCopy || {};
    const smallMediaUrl = getMediaUrl(smallMedia);

    const mainContent = (
      <div className={classNames(
        'sui-flex',
        'sui-items-center',
        'sui-paper-outlined',
        'sui-p-4',
        'sui-max-h-[148px]',
        'md:sui-max-h-[100px]',
        'lg:sui-max-h-[80px]',
        { 'hover:sui-shadow-lg': destinationUrl }
      )}
      >
        {/* Small Media container */}
        {smallMediaUrl && (
          <div className="sui-min-w-12 sui-min-h-12 sui-mr-4 sui-flex sui-items-center">
            <img src={smallMediaUrl} alt={altText} width="48px" height="48px" />
          </div>
        )}
        {/* Title & Subtitle container */}
        <div className="sui-grid sui-items-center lg:sui-grid-flow-col lg:sui-gap-4">
          <Typography
            variant="h3"
            height="tight"
            lineClamp={{
              default: '2',
              sm: '2',
              md: '1',
              lg: '1',
              xl: '1',
              '2xl': '1',
            }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              underline="none"
              variant="body-base"
              height="tight"
              lineClamp={{
                default: '3',
                sm: '3',
                md: '2',
                lg: '2',
                xl: '2',
                '2xl': '2',
              }}
            >
              {subtitle}
            </Typography>
          )}
        </div>
      </div>
    );

    return (
      <div
        ref={ref}
        // eslint-disable-next-line react/no-unknown-property
        clickid={clickID}
        data-component-id={componentId}
        data-component-name="PiccolaComposableBanner"
        data-component-position={componentPosition}
        data-component="PiccolaComposableBanner"
        data-testid="piccola-composable-banner"
        id={`${componentClass}-${componentPosition}-${componentId}`}
      >
        {/* Main Content */}
        {destinationUrl ? <Link underline="none" href={destinationUrl}>{mainContent}</Link> : mainContent}
        {/* Legal Text */}
        {legalText && (
          <div className="sui-pt-2">
            {legalUrl ? (
              <Link href={legalUrl} target="_blank" variant="body-xs">
                {legalText}
              </Link>
            ) : (
              <Typography variant="body-xs">{legalText}</Typography>
            )}
          </div>
        )}
      </div>
    );
  }
);

PiccolaComposableBanner.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  componentPosition: numberType,
  lazyLoad: bool,
};

PiccolaComposableBanner.defaultProps = {
  componentClass: '',
  componentPosition: 1,
};

PiccolaComposableBanner.displayName = 'PiccolaComposableBanner';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1',
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: string(),
      })
    ),
  }),
  damDownloadedContent: shape({
    url: string(),
  }),
  __typename: typename('DamMedia'),
});

PiccolaComposableBanner.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['PiccolaComposableBanner']).isRequired(),
  }).shape({
    PiccolaComposableBanner: namedFragment({
      inline: true,
      fragmentType: 'PiccolaComposableBanner',
    }).shape({
      id: string(),
      title: string(),
      subtitle: string(),
      destinationUrl: string(),
      smallMedia: DamMediaFragment,
      altText: string(),
      legalCopy: shape({
        legalText: string(),
        legalUrl: string(),
      }),
    }),
  }),
};

export { PiccolaComposableBanner };
